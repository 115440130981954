<template>
<div>
  <div class="columns is-mobile is-centered has-text-left">
      <div class="column is-9">
        <div class="is-flex is-justify-content-flex-end">
          <router-link :to="`/order/new?u=${user.id}`">
            <b-button
              type="is-success is-light"
              icon-pack="fa"
              icon-left="shopping-cart"
              class="mx-2"
              >Add Order</b-button
            >
          </router-link>
          <b-dropdown aria-role="list">
            <template #trigger>
                <b-button
                    label="..."
                    type="is-light"
                />
            </template>
            <b-dropdown-item aria-role="listitem">
              <a href="#past-orders">
                <span class="icon">
                  <i class="fa fa-shopping-bag"></i>
                </span>
                <span
                  icon-pack="fa"
                  icon-left="shopping-bag"
                >
                  View Orders
                </span>
              </a>
            </b-dropdown-item>
            <b-dropdown-item v-if="user.email" aria-role="listitem">
              <span class="icon">
                <i class="fa fa-envelope"></i>
              </span>
              <router-link
                :to="`/mail?e=${user.email}`"
              >
                  Send Email
              </router-link>
            </b-dropdown-item>
          </b-dropdown>

        </div>
        <div class="subtitle has-text-left is-flex is-align-items-center has-text-weight-bold">
          {{ user.firstName }} {{ user.lastName }}
        </div>
        <div class="title has-text-left is-flex is-align-items-center"
        >+44{{ user.phoneNumber }}
        <b-tag
          v-bind:class="getClassByStatus(user.status) + ' mx-1 is-size-7'">{{
            user.status
          }}</b-tag>
        </div>
        <div v-for="altUser in allUsers" :key="altUser.id"
          class="is-size-8 has-text-left is-flex is-align-items-center"
          @click="navigateToCandidate(altUser.id)"
        >
        <a>
        {{ altUser.firstName }} {{ altUser.lastName}} +44{{ altUser.phoneNumber }}
        ({{ altUser.email }})</a>
        <b-tag
          v-bind:class="getClassByStatus(altUser.status) + ' mx-1 is-size-7'">{{
            altUser.status
          }}</b-tag>

        </div>
        <hr>
        <div class="columns is-mobile is-centered has-text-left">
          <div class="column is-3">
            <b>Assigned</b>
            <br>
                <b-tag v-if="user.agentId" type="is-danger">
                  {{ getAgentById(user.agentId)}}
                </b-tag>
          </div>
          <div class="column is-3">
            <b>Date</b>
            <br />
            <b>{{getHumanDate(user.createdAt, true)}}</b>
          </div>
          <div class="column is-3">
            <b>Products</b>
            <br />
            <span v-for="order in user.Orders" :key="order.id">
              <b-tag v-for="(item, index) in order.items" class="mx-1" :key="index">{{
                item
              }}</b-tag>
            </span>
          </div>
          <div class="column is-3">
            <LeadAssigner />
          </div>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import {
  getClassByStatus,
  navigateToCandidate,
  getAgentById,
  getHumanDate,
} from '@/common';
import LeadAssigner from './LeadAssigner.vue';

export default {
  name: 'LeadSummary',
  components: {
    LeadAssigner,
  },
  methods: {
    ...mapActions([
      'setUserId',
    ]),
    getClassByStatus,
    navigateToCandidate,
    getAgentById,
    getHumanDate,
  },
  computed: {
    ...mapState({
      user: ({ primary: { user } }) => user,
      allUsers: ({ primary: { allUsers } }) => allUsers,
      allAgents: ({ agentData: { allAgents } }) => allAgents,
    }),
  },
};
</script>
