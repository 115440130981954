<template>
  <section>
    <div class="columns is-centered has-text-left">
      <div class="column is-three-quarters mt-5">
      <div class="title mb-10">Candidate Detail</div>
        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Name</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-select
                  id="title"
                  @input="updateTitle"
                  :value="user.title"
                  ref="title"
                  placeholder="Title"
                >
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Miss">Miss</option>
                  <option value="Dr">Dr</option>
                  <option value="Prof">Prof</option>
                  <option value="Other">Other</option>
                </b-select>
              </b-field>
            </div>
            <div class="field">
              <b-field>
                <ValidationProvider
                  rules="namePart"
                  :immediate="validateInitialState"
                  v-slot="{ errors }"
                >
                  <b-input
                    @input="updateFirstName"
                    id="first-name"
                    ref="first-name"
                    :value="user.firstName"
                    placeholder="First Name"
                    type="text"
                    minlength="2"
                    maxlength="35"
                    icon-right="edit"
                    icon-right-clickable
                    @icon-right-click="unsetFirstNameDisabled"
                    :disabled="firstNameDisabled"
                  ></b-input>
                  <span class="error-message has-text-danger">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="field">
              <b-field>
                <ValidationProvider
                  rules="namePart"
                  :immediate="validateInitialState"
                  v-slot="{ errors }"
                >
                  <b-input
                    @input="updateMiddleName"
                    id="middle-name"
                    ref="middle-name"
                    :value="user.middleName"
                    placeholder="Middle Name"
                    type="text"
                    minlength="2"
                    maxlength="35"
                    icon-right="edit"
                    icon-right-clickable
                    @icon-right-click="unsetMiddleNameDisabled"
                    :disabled="middleNameDisabled"
                  ></b-input>
                  <span class="error-message has-text-danger">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="field">
              <b-field>
                <ValidationProvider
                  rules="namePart"
                  :immediate="validateInitialState"
                  v-slot="{ errors }"
                >
                  <b-input
                    @input="updateLastName"
                    id="last-name"
                    ref="last-name"
                    :value="user.lastName"
                    placeholder="Last Name"
                    type="text"
                    minlength="2"
                    maxlength="35"
                    icon-right="edit"
                    icon-right-clickable
                    @icon-right-click="unsetLastNameDisabled"
                    :disabled="lastNameDisabled"
                  ></b-input>
                  <span class="error-message has-text-danger">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Date of Birth</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field v-if="user.dateOfBirth">
                <b-datepicker
                  @input="updateDOB"
                  id="date-of-birth"
                  ref="date-of-birth"
                  placeholder="Click to select date of birth..."
                  :value="new Date(user.dateOfBirth)"
                  :date-formatter="
                    (date) => new Intl.DateTimeFormat('in-IN').format(date)
                  "
                  icon="calendar-today"
                ></b-datepicker>
              </b-field>
              <b-field v-else>
                <b-datepicker
                  @input="updateDOB"
                  id="date-of-birth"
                  ref="date-of-birth"
                  placeholder="Click to select date of birth..."
                  :date-formatter="
                    (date) => new Intl.DateTimeFormat('in-IN').format(date)
                  "
                  icon="calendar-today"
                ></b-datepicker>
              </b-field>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">NI Number</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <ValidationProvider
                  rules="NI"
                  :immediate="validateInitialState"
                  v-slot="{ errors }"
                >
                  <b-input
                    @input="updateNI"
                    id="national-insurance"
                    ref="national-insurance"
                    :value="user.nationalInsuranceNumber"
                    :disabled="NIDisabled"
                    icon-right="edit"
                    icon-right-clickable
                    @icon-right-click="unsetNIDisabled"
                    type="text"
                    minlength="6"
                    maxlength="15"
                  ></b-input>
                  <span class="error-message has-text-danger">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Registration number</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <ValidationProvider
                  rules="NI"
                  :immediate="validateInitialState"
                  v-slot="{ errors }"
                >
                  <b-input
                    @input="updateRegistrationNumber"
                    id="registration-number"
                    ref="registration-number"
                    :value="user.cardRegistrationNumber"
                    :disabled="NIDisabled"
                    icon-right="edit"
                    icon-right-clickable
                    @icon-right-click="unsetNIDisabled"
                    type="text"
                    minlength="6"
                    maxlength="15"
                  ></b-input>
                  <span class="error-message has-text-danger">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Phone Number</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <ValidationProvider
                  rules="phone"
                  :immediate="validateInitialState"
                  v-slot="{ errors }"
                >
                  <b-input
                    @input="updatePhoneNumber"
                    id="phone-number"
                    ref="phone-number"
                    :value="user.phoneNumber"
                    :disabled="phoneNumberDisabled"
                    @icon-right-click="unsetPhoneNumberDisabled"
                    pattern="[0-9]*"
                    minlength="10"
                    maxlength="11"
                    icon-right="edit"
                    icon-right-clickable
                    type="tel"
                  ></b-input>
                  <span class="error-message has-text-danger">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Email</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <ValidationProvider
                  rules="email"
                  :immediate="validateInitialState"
                  v-slot="{ errors }"
                >
                  <b-input
                    @input="updateEmail"
                    id="email"
                    ref="email"
                    :value="user.email"
                    :disabled="emailDisabled"
                    icon-right="edit"
                    icon-right-clickable
                    @icon-right-click="unsetEmailDisabled"
                    type="email"
                  ></b-input>
                  <span class="error-message has-text-danger">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </b-field>
              <b-collapse
                    class="mt-2"
                    :open="!!user.alternateEmail"
                    position="is-bottom"
                    aria-id="additionalEmail"
                  >
                    <template #trigger="props">
                        <a class="button is-info is-inverted" aria-controls="additionalEmail">
                            {{ !props.open ? 'Add Alternate Email' : '' }}
                        </a>
                    </template>
                    <b-field>
                      <ValidationProvider
                        rules="email"
                        :immediate="validateInitialState"
                        v-slot="{ errors }"
                      >
                        <b-input
                          placeholder="Alternate Email"
                          @input="updateAltEmail"
                          id="alt-email"
                          ref="alt-email"
                          :value="user.alternateEmail"
                          :disabled="user.alternateEmail"
                          icon-right="edit"
                          icon-right-clickable
                          @icon-right-click="unsetAltEmailDisabled"
                          type="email"
                          icon-pack="fas"
                          icon="envelope"
                        ></b-input>
                        <span class="error-message has-text-danger">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                  </b-field>
                  </b-collapse>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Address</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <ValidationProvider
                  rules="address"
                  :immediate="validateInitialState"
                  v-slot="{ errors }"
                >
                  <b-input
                    @input="updateAddress"
                    id="address"
                    ref="address"
                    :value="user.address"
                    type="text"
                    minlength="1"
                    maxlength="80"
                    :disabled="addressDisabled"
                    @icon-right-click="unsetAddressDisabled"
                    icon-right="edit"
                    icon-right-clickable
                  ></b-input>
                  <span class="error-message has-text-danger">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Town</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <ValidationProvider
                  rules="town"
                  :immediate="validateInitialState"
                  v-slot="{ errors }"
                >
                  <b-input
                    @input="updateTown"
                    id="town"
                    ref="town"
                    :value="user.town"
                    minlength="2"
                    maxlength="30"
                    :disabled="townDisabled"
                    @icon-right-click="unsetTownDisabled"
                    icon-right="edit"
                    icon-right-clickable
                    type="text"
                  ></b-input>
                  <span class="error-message has-text-danger">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Post Code</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <ValidationProvider
                  rules="postcode"
                  :immediate="validateInitialState"
                  v-slot="{ errors }"
                >
                  <b-input
                    @input="updatePostcode"
                    id="post-code"
                    ref="post-code"
                    minlength="5"
                    maxlength="10"
                    :value="user.postcode"
                    :disabled="postcodeDisabled"
                    @icon-right-click="unsetPostcodeDisabled"
                    icon-right="edit"
                    icon-right-clickable
                    type="text"
                  ></b-input>
                  <span class="error-message has-text-danger">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Trade</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>

                <b-autocomplete
                  v-model="trade"
                  ref="autocomplete"
                  :data="filteredTradeArray"
                  @select="updateTrade"
                  :selectable-footer="false">
                  <template #footer>
                      <a @click="showAddTrade"><span> Add new... </span></a>
                  </template>
                  <template #empty>No results for {{trade}}</template>
                </b-autocomplete>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Status</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-select
                  @input="updateStatus"
                  :value="user.status"
                  id="status"
                  ref="status"
                  expanded
                >
                  <option value="Open">Open</option>
                  <option value="Duplicate">Duplicate</option>
                  <option value="Lead Dial 1">Lead Dial 1</option>
                  <option value="Lead Dial 2">Lead Dial 2</option>
                  <option value="Answering Machine">Answering Machine</option>
                  <option value="Not Reachable">Not Reachable</option>
                  <option value="No Answer">No Answer</option>
                  <option value="Not Interested">Not Interested</option>
                  <option value="Pending Payment">Pending Payment</option>
                  <option value="Sale">Sale</option>
                  <option value="Callback">Callback</option>
                  <option value="Manager Callback">Manager Callback</option>
                  <option value="Refund">Refund</option>
                  <option value="Dispute">Dispute</option>
                  <option value="Miscellaneous">Miscellaneous</option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Callback</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-datetimepicker
                  v-if="user.callback"
                  @input="updateCallback"
                  :value="new Date(user.callback)"
                  rounded
                  placeholder="Schedule a Callback..."
                  icon="calendar-today"
                  horizontal-time-picker
                >
                </b-datetimepicker>
                <b-datetimepicker
                  v-else
                  @input="updateCallback"
                  rounded
                  placeholder="Schedule a Callback..."
                  icon="calendar-today"
                  horizontal-time-picker
                >
                </b-datetimepicker>
              </b-field>
            </div>
          </div>
        </div>

        <div v-if="user.comment" class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Comment</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-input
                  id="comment"
                  ref="comment"
                  :value="user.comment"
                  type="textarea"
                  disabled
                ></b-input>
              </b-field>
            </div>
          </div>
        </div>

        <div class="mt-5 mb-10">
          <div class="field has-text-centered">
            <b-button
              expanded
              icon-right="arrow-circle-right"
              type="is-info"
              @click.prevent="formSubmit"
            >
              Submit
            </b-button>
          </div>
        </div>
    </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  createSnackbar,
} from '@/common';
import { occupations } from '@/assets/data';

export default {
  name: 'Personal',
  components: {
  },
  data: () => ({
    validateInitialState: true,
    firstNameDisabled: true,
    middleNameDisabled: true,
    lastNameDisabled: true,
    NIDisabled: true,
    emailDisabled: true,
    altEmailDisabled: true,
    phoneNumberDisabled: true,
    addressDisabled: true,
    postcodeDisabled: true,
    townDisabled: true,
    trades: occupations,
    trade: '',
    updatedFields: {},
  }),
  computed: {
    ...mapState({
      user: ({ primary: { user } }) => user,
    }),
    filteredTradeArray() {
      return this.trades.filter(
        (option) => option
          .toString()
          .toLowerCase()
          .indexOf(this.trade.toLowerCase()) >= 0,
      );
    },
  },
  mounted() {
    this.trade = this.user.trade || '';
  },
  watch: {
    user() {
      this.trade = this.user.trade || '';
    },
  },
  methods: {
    ...mapActions(['updateCandidateDetails']),
    createSnackbar,
    unsetFirstNameDisabled() {
      this.firstNameDisabled = false;
    },
    unsetMiddleNameDisabled() {
      this.middleNameDisabled = false;
    },
    unsetLastNameDisabled() {
      this.lastNameDisabled = false;
    },
    unsetNIDisabled() {
      this.NIDisabled = false;
    },
    unsetEmailDisabled() {
      this.emailDisabled = false;
    },
    unsetAltEmailDisabled() {
      this.altEmailDisabled = false;
    },
    unsetPhoneNumberDisabled() {
      this.phoneNumberDisabled = false;
    },
    unsetAddressDisabled() {
      this.addressDisabled = false;
    },
    unsetPostcodeDisabled() {
      this.postcodeDisabled = false;
    },
    unsetTownDisabled() {
      this.townDisabled = false;
    },
    updateTitle(e) {
      this.updatedFields.title = e;
    },
    updateFirstName(e) {
      this.updatedFields.firstName = e;
    },
    updateMiddleName(e) {
      this.updatedFields.middleName = e;
    },
    updateLastName(e) {
      this.updatedFields.lastName = e;
    },
    updateDOB(e) {
      this.updatedFields.dateOfBirth = e;
    },
    updateNI(e) {
      this.updatedFields.nationalInsuranceNumber = e;
    },
    updateRegistrationNumber(e) {
      this.updatedFields.cardRegistrationNumber = e;
    },
    updateEmail(e) {
      this.updatedFields.email = e;
    },
    updateAltEmail(e) {
      this.updatedFields.alternateEmail = e;
    },
    updatePhoneNumber(e) {
      this.updatedFields.phoneNumber = e;
    },
    updateAddress(e) {
      this.updatedFields.address = e;
    },
    updateTown(e) {
      this.updatedFields.town = e;
    },
    updateTrade(e) {
      this.updatedFields.trade = e;
    },
    updatePostcode(e) {
      this.updatedFields.postcode = e;
    },
    updateStatus(e) {
      this.updatedFields.status = e;
    },
    showAddTrade() {
      this.$buefy.dialog.prompt({
        message: 'Add New Trade',
        inputAttrs: {
          placeholder: 'e.g. Labourer',
          maxlength: 60,
          value: this.trade,
        },
        confirmText: 'Add',
        onConfirm: (value) => {
          this.trades.push(value);
          this.$refs.autocomplete.setSelected(value);
        },
      });
    },
    updateCallback(e) {
      this.updatedFields.callback = e;
      this.updatedFields.status = 'Callback';
    },
    async formSubmit() {
      try {
        await this.updateCandidateDetails(this.updatedFields);
        return this.createSnackbar('Personal Details have been updated', true);
      } catch (error) {
        console.error(error);
        return this.createSnackbar(error.message);
      } finally {
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>

<style>
.has-text-danger {
  font-weight: bold;
}

.has-display-inline {
  display: inline;
}

.ml-3 {
  margin-left: 0.75em;
}

.mt-5 {
  margin-top: 1.25em;
}

.mt-10 {
  margin-top: 3em;
}

.mt-2 {
  margin-top: 0.5em;
}

.mx-2 {
  margin-left: 1em;
  margin-right: 1em;
}

.mb-10 {
  margin-bottom: 2em;
}

.mb-20 {
  margin-bottom: 5em;
}
</style>
