<template>
    <div class="container">
        <LeadDetails :key="userId" v-if="!isLoading" />
        <Loading v-else />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import LeadDetails from '@/components/EditForms/LeadDetails/LeadDetails.vue';
import Loading from '@/components/Loading.vue';

export default {
  name: 'Leads',
  data: () => ({
    isLoading: true,
  }),
  async mounted() {
    await this.updateCandidate();
  },
  watch: {
    async $route() {
      await this.updateCandidate();
    },
  },
  computed: {
    ...mapState({
      user: ({ primary: { user } }) => user,
      userId: ({ primary: { userId } }) => userId,
    }),
  },
  methods: {
    ...mapActions(['getLeadDetails', 'setUserId']),
    async updateCandidate() {
      this.setUserId(this.$route.params.userId);
      try {
        await this.getLeadDetails();
      } catch (error) {
        this.$router.push({ name: 'Login' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    LeadDetails,
    Loading,
  },
};
</script>

<style scoped>
  .blended {
      filter: saturate(70%);
  }
</style>
