<template>
  <section>
      <h2 id="past-orders" class="title">
        Past Orders for <i>{{user.title}} {{user.firstName}} {{user.lastName}}</i>
      </h2>
      <div v-for="order in user.Orders" :key="order.id" class="columns is-centered">
        <div class="column is-9">
          <nav class="level">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">{{ new Date(order.createdAt).toDateString() }}</p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p v-if="order.agentId" class="title">
                  <b-tag type="is-info is-light" >{{
                    getAgentById(order.agentId)
                  }}</b-tag>
                </p>
                <p v-else class="heading">
                  <b-tag>No Agent</b-tag>
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="title">£{{ order.amount / 100 }}</p>
                <span
                  v-if="order.Payment && order.Payment.chargeId" class="mx-1"
                >
                  <b-tag
                    :type="order.Payment.Charge.status === 'created' ? 'is-success' : 'is-danger'"
                    >{{
                    order.Payment.Charge.status === 'created' ? 'Payment Complete' : 'Refunded'
                  }}</b-tag>
                </span>
                <span class="mx-1" v-else>
                  <b-tag>
                    payment not started
                </b-tag>
                </span>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p v-for="(item, index) in order.items" :key="index" class="title">
                  <b-tag type="is-light" class="mx-1">{{
                    item
                  }}</b-tag>
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <p class="heading">
                <router-link :to="`/order/new?o=${order.id}`"
                >
                  <b-button icon-left="directions" type="is-light is-success">
                    To Order
                  </b-button>
                </router-link
                >
              </p>
            </div>
            <div class="level-item has-text-centered">
              <b-button
                @click="removeOrder(order.id)"
                icon-left="trash-alt"
                :disabled="getDeleteDisabledStatus(order)"
                type="is-danger">
                Delete
              </b-button>
            </div>
          </nav>
        </div>
      </div>
    </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getAgentById, createSnackbar } from '@/common';

export default {
  name: 'PastOrders',
  methods: {
    ...mapActions(['deleteOrder']),
    getAgentById,
    createSnackbar,
    async removeOrder(orderId) {
      try {
        await this.deleteOrder(orderId);
      } catch (error) {
        console.error(error);
        this.createSnackbar(error.message);
      }
    },
    getDeleteDisabledStatus(order) {
      if (order.Payment && order.Payment.chargeId) {
        return true;
      }
      if (order.agentId
        && this.getAgentById(order.agentId)
        && this.getAgentById(order.agentId).toUpperCase() === 'ONLINE'
      ) {
        return true;
      }
      return false;
    },
  },
  computed: {
    ...mapState({
      user: ({ primary: { user } }) => user,
      allAgents: ({ agentData: { allAgents } }) => allAgents,
    }),
  },
};
</script>
