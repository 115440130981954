<template>
  <div class="container" v-if="user">
    <LeadSummary/>
    <hr>
    <Personal />
    <hr>
    <Comments :comments="user.Comments" type="user" />
    <hr>
    <Activity :activities="user.Activities" />
    <hr />
    <PastOrders/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Personal from '@/components/EditForms/LeadDetails/Personal/Personal.vue';
import LeadSummary from '@/components/EditForms/LeadDetails/Personal/LeadSummary.vue';
import PastOrders from '@/components/EditForms/LeadDetails/Personal/PastOrders.vue';
import Comments from '../../Comments.vue';
import Activity from '../../Activity.vue';

export default {
  name: 'LeadDetails',
  components: {
    LeadSummary,
    Personal,
    PastOrders,
    Comments,
    Activity,
  },
  methods: {
  },
  mounted() {
    if (this.$route.query.n) {
      const el = document.querySelector(`#${this.$route.query.n}`);
      if (el) el.scrollIntoView();
    }
  },
  data: () => ({
    isOpen: false,
    isOrderOpen: false,
  }),
  computed: {
    ...mapState({
      user: ({ primary: { user } }) => user,
    }),
  },
};
</script>

<style scoped>
.mt-4 {
  margin-top: 4em;
}

.ml-2 {
  margin-left: 1em;
}

.mb-10 {
  margin-bottom: 5em;
}

.container {
  margin-bottom: 4em;
  margin-top: 3em;
}
</style>
